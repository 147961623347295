import { CookieService } from 'ngx-cookie';
import { IUserTracking } from 'app/shared/model/bp.model';
import { HttpResponse } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { Injectable } from "@angular/core";
import { UserTrackingApi } from "app/shared/dataservices/user-tracking.api";
import { getCookie, setCookie } from "app/shared/util/tracking-cookie.utils";

@Injectable({ providedIn: 'root' })
export class UserTrackingHandlerService {
    constructor(
        private cookieService: CookieService,
        private userTrackingApi: UserTrackingApi
    ) {
    }

    async initUserTracking(userId: number): Promise<void> {
        const cookieGclid = getCookie(this.cookieService, 'gclid');
        const cookieFbclid = getCookie(this.cookieService, 'fbclid');

        try {
            if (cookieGclid || cookieFbclid) {
                const payload: IUserTracking = {
                    gclid: cookieGclid || null,
                    fbclid: cookieFbclid || null,
                };
                await firstValueFrom(this.userTrackingApi.update(userId, payload));
                console.log('[Tracking] Updated DB from cookies');
            }

            if (!cookieGclid || !cookieFbclid) {
                const response: HttpResponse<IUserTracking> = await firstValueFrom(this.userTrackingApi.find(userId));
                const tracking = response.body;

                if (tracking) {
                    if (!cookieGclid && tracking.gclid) {
                        setCookie(this.cookieService, 'gclid', tracking.gclid);
                        console.log('[Tracking] Restored gclid from DB to cookies');
                    }
                    if (!cookieFbclid && tracking.fbclid) {
                        setCookie(this.cookieService, 'fbclid', tracking.fbclid);
                        console.log('[Tracking] Restored fbclid from DB to cookies');
                    }
                }
            }
        } catch (err) {
            console.warn('[Tracking] Failed to sync tracking data', err);
        }
    }
}
