import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
    IUserReport,
    IUserReportSetting,
    IUserReportStructure,
    IUserReportStructureSheet,
    IUserReportStructureSheetCell,
    ReportType,
    UserReportApi
} from 'app/shared/dataservices/user-report.api';
import { forkJoin, lastValueFrom } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HttpResponse } from '@angular/common/http';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { BpAlertService } from 'app/shared/services/bp-alert.service';
import { AccountService } from "app/core/auth/account.service";
import { Role } from "app/shared/model/common.model";

@Component({
    selector: 'bp-user-report-modal',
    templateUrl: './user-report-modal.component.html',
    styleUrls: ['user-report-modal.scss']
})
export class UserReportModalComponent implements OnInit {
    @BlockUI() blockUI: NgBlockUI;

    public type: ReportType;
    public role?: Role;

    protected userReportStructure: IUserReportStructure;
    protected initialUserReports: IUserReport[] = [];

    private get authority(): Role | null {
        return this.role ?? this.accountService.isHomeowner() ? 'ROLE_HOMEOWNER' : null;
    }

    constructor(
        private userReportApi: UserReportApi,
        private accountService: AccountService,
        private alertService: BpAlertService,
        private activeModal: NgbActiveModal,
    ) {
    }

    ngOnInit(): void {
        this.blockUI.start('Loading data..');

        forkJoin([
            lastValueFrom(this.userReportApi.getReportStructure(this.type, this.authority)),
            lastValueFrom(this.userReportApi.getReportSettings(this.type, this.authority))
        ]).pipe(
            finalize(() => {
                this.blockUI.stop();
            })
        ).subscribe((res: [HttpResponse<IUserReportStructure>, HttpResponse<IUserReport[]>]) => {
            this.userReportStructure = res[0].body;

            this.initialUserReports = res[1].body;
            this.userReportStructure.sheets.forEach((sheet) => {
                sheet._checked = this.initialUserReports.length === 0 ? !(sheet.type.includes('CSS') || sheet.type.includes('INCLUDE_VAT')) : this.initialUserReports.find((userReport) => {
                    return (userReport.sheet.id === sheet.id) && (userReport?.column == null);
                }) != null;

                sheet?.columns?.forEach(column => {
                    column._checked = this.initialUserReports.length === 0 ? (column.type !== 'PROFIT' && !sheet.type.includes('CSS') && !column.type.includes('CSS')) : this.initialUserReports.find((userReport) => {
                        return (userReport.sheet.id === sheet.id) && (userReport.column?.id === column.id);
                    }) != null;
                })
            })
        })
    }

    protected typeToLabel(type: ReportType): string {
        switch (type) {
            case "EXCEL": return "Excel";
            case "WORD": return "Word";
            case "PDF": return "PDF";
            default: return "";
        }
    }
    protected close(): void {
        this.activeModal.close();
    }

    protected toggleAllColumns(sheet: IUserReportStructureSheet): void {
        sheet.columns.forEach((columns) => {
            columns._checked = sheet._checked;
        })
    }

    protected onSelectColumn(sheet: IUserReportStructureSheet, column: IUserReportStructureSheetCell): void {
        if (!column._checked) {
            sheet._checked = sheet.columns.find(column => column._checked) != null;
        }

        if (column._checked) {
            sheet._checked = true;
        }
    }

    protected generateExport(): void {
        const data: IUserReportSetting = {
            newSettings: [],
            removedSettings: []
        }

        this.userReportStructure.sheets.forEach((sheet) => {
            const userReport = this.initialUserReports.find((userReport) => {
                return (userReport.sheet.id === sheet.id) && (userReport?.column == null);
            });
            if (sheet._checked) {
                if (!userReport) {
                    data.newSettings.push({
                        report: { id: this.userReportStructure.id, type: this.userReportStructure.type },
                        sheet: { id: sheet.id, type: sheet.type }
                    })
                }
            } else {
                if (userReport != null) {
                    data.removedSettings.push(userReport.id);
                }
            }
            sheet.columns.forEach((column) => {
                const userReport = this.initialUserReports.find((userReport) => {
                    return (userReport.sheet.id === sheet.id) && (userReport.column?.id === column.id);
                });

                if (column._checked) {
                    if (!userReport) {
                        data.newSettings.push({
                            report: { id: this.userReportStructure.id, type: this.userReportStructure.type },
                            sheet: { id: sheet.id, type: sheet.type },
                            column: { id: column.id, type: column.type }
                        })
                    }
                } else {
                    if (userReport != null) {
                        data.removedSettings.push(userReport.id);
                    }
                }
            })
        })

        lastValueFrom(this.userReportApi.saveReportSettings(this.type, data, this.authority)).then(() => {
            this.alertService.success(`User ${this.type} report settings successfully saved`);
            this.activeModal.close(true);
        })
    }
}
