import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SERVER_API_URL } from 'app/app.constants';
import { IUserTracking } from 'app/shared/model/bp.model';

@Injectable({ providedIn: 'root' })
export class UserTrackingApi {
    public resourceUrl = SERVER_API_URL + 'api/users';

    constructor(private http: HttpClient) {
    }

    update(userId: number, userTracking: IUserTracking): Observable<HttpResponse<IUserTracking>> {
        return this.http.put<IUserTracking>(`${this.resourceUrl}/${userId}/tracking`, userTracking, { observe: 'response' });
    }

    find(userId: number): Observable<HttpResponse<IUserTracking>> {
        return this.http.get<IUserTracking>(`${this.resourceUrl}/${userId}/tracking`, { observe: 'response' });
    }
}
