import { CookieService } from 'ngx-cookie';

const COOKIE_EXPIRE_SECONDS = 30 * 24 * 60 * 60;

export function getCookie(cookieService: CookieService, name: string): string | null {
    return cookieService.get(name) || null;
}

export function setCookie(cookieService: CookieService, name: string, value: string): void {
    if (value) {
        cookieService.put(name, value, {
            path: '/',
            domain: '.buildpartner.com',
            expires: new Date(Date.now() + COOKIE_EXPIRE_SECONDS * 1000),
        });
    }
}
